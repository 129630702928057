<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			label-width="82px"
		>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="提现时间" >
						<el-date-picker
							size="small"
							v-model="createDateRange"
							type="daterange"
							@change="dateChange"
							range-separator="-"
							value-format="yyyy-MM-dd"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							style="width: 100%;"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="提现账户">
						<el-select
							v-model="queryParams.balanceType"
							placeholder="请选择提现账户"
							size="small"
							style="width: 100%"
						>
							<el-option label="全部" value=""></el-option>
							<el-option label="交易分润" value="POS_PROFIT"></el-option>
							<el-option
								label="活动分润"
								value="ACTIVE_PROFIT"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="打款状态">
						<el-select
							v-model="queryParams.transferStatus"
							placeholder="请选择打款状态"
							size="small"
							style="width: 100%"
						>
							<el-option label="全部" value=""></el-option>
							<el-option label="初始化" value="INIT"></el-option>
							<el-option label="提交失败" value="UNCOMMIT"></el-option>
							<el-option label="出款中" value="WAITING"></el-option>
							<el-option label="出款成功" value="SUCCESS"></el-option>
							<el-option label="出款失败" value="FAILED"></el-option>
							<el-option label="未知" value="UNKNOW"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="代理商名称">
						<el-input
							v-model="queryParams.userName"
							clearable
							placeholder="请输入代理商名称"
							style="width: 100%"
							size="small"
							@keyup.enter.native="handleQuery"
						/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="代理商编号">
						<el-input
							v-model="queryParams.userNo"
							clearable
							placeholder="请输入代理商编号"
							size="small"
							@keyup.enter.native="handleQuery"
						/>
					</el-form-item>
				</el-col>
			</el-row>	
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'EXTRACTION_ORDER_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'EXTRACTION_ORDER_QUERY'"
				>
					重置
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false
		};
	},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAdd() {
			this.showCustomForm = true;
		},
		// dealWithQueryParams() {
		// 	let [beginExtractionTime, endExtractionTime] = this.createDateRange;
		// 	this.queryParams.beginExtractionTime =
		// 		beginExtractionTime && beginExtractionTime + " 00:00:00";
		// 	this.queryParams.endExtractionTime =
		// 		endExtractionTime && endExtractionTime + " 23:59:59";

		// 	let [startUpdateTime, endUpdateTime] = this.updateDateRange;
		// 	this.queryParams.startUpdateTime =
		// 		startUpdateTime && startUpdateTime + " 00:00:00";
		// 	this.queryParams.endUpdateTime =
		// 		endUpdateTime && endUpdateTime + " 23:59:59";
		// },
		// 日期事件
		dealWithQueryParams() {
			console.log("dealWithQueryParams----->", this.createDateRange);
			// 判断如果有时间则重置为空数组
			if (!this.createDateRange) {
				this.createDateRange = [];
			}
			// 开始创建时间,截至创建时间
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		// 触发日期事件
		dateChange(val) {
			// 判断没有选中数据把transTime赋值为空
			if (!val) {
				this.queryParams.transTime = "";
			} else {
				// 把调整日期的对象数据转换为JSON字符串，用逗号拼接起来。
				// 再将字符串数据解析为对象————深拷贝
				this.queryParams.transTime = JSON.parse(
					JSON.stringify(val)
				).join(",");
				console.log("this.queryParams----->182", this.queryParams);
			}
		}
	}
};
</script>

<style scoped></style>
